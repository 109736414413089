import React from "react"
import BackgroundShape from "../components/backgroundShape"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Container from "../components/container"
import Head from "../components/head"
import Layout from "../components/layout"
import img from "../assets/icons/illustration.svg"
import Button from "../components/button"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import SubHeader from "../components/subHeader"
import Footer from "../components/footer"
import FeaturesCardMd from "../components//featureCardMd"
import LandingCard from "../components//landingCard"
import { graphql, useStaticQuery } from "gatsby"



export const query = graphql`
	query($slug: String!) {
		contentfulPlatform(slug: {eq: $slug}) {
		title
		metaDescription {
			metaDescription
		}
		summary
		slug
		setupTime
		orderSync
		inventorySync
		updated
		breakdown{
			childMdx {
			body
			}
		}
		benefits {
			benefits
			childMdx {
			body
			}
		}
		integrationCategory {
			title
			slug
		}
		beta
		task {
			slug
		}
		icon {
			icon
			title
		}
		}
		allContentfulFeature {
		edges {
			node {
			title
			slug
			icon
			}
		}
		}
	}
`

const Partner = (props) => {
  const setupTime = props.data.contentfulPlatform.setupTime
  const orderSync = props.data.contentfulPlatform.orderSync
  const inventorySync = props.data.contentfulPlatform.inventorySync
  const task = props.data.contentfulPlatform.task ? props.data.contentfulPlatform.task : ""
  let secondDelay = 0
  // const description = props.data.contentfulPlatform.benefits.childMdx ? props.data.contentfulPlatform.benefits.benefits.childMdx.body : props.data.contentfulPlatform.summary
   if (!props.data.contentfulPlatform.updated) {
     return (
      <Layout navbar="transparent">
        <Head title={''+props.data.contentfulPlatform.title} description={props.data.contentfulPlatform.metaDescription ? props.data.contentfulPlatform.metaDescription.metaDescription : "Dropshipping and multi vendor marketplace automation for Shopify, Magento and WooCommerce. Sync orders, inventory, shipping & payments."}/>
        <BackgroundShape style={{shape: "clip-fixed", color: "gradient", particles: true}}/>
        <Container style={{classes: "container" }}>
          <Header content={{title: props.data.contentfulPlatform.title, text: ""}} style={{color: "text-center"}}/>
          <img src={require(`../assets/icons/platform/round/${props.data.contentfulPlatform.slug}.svg`)} alt={props.data.contentfulPlatform.title} className="test-int" />
          <div className="integrations-opt-grid">
            { setupTime ? <div><p className="integrations-opt-grid__headers">Setup time</p><p className="integrations-opt-grid__text">{props.data.contentfulPlatform.setupTime}</p></div> : "" }
            { orderSync ?  <div><p className="integrations-opt-grid__headers">Order Sync</p><p className="integrations-opt-grid__text">{props.data.contentfulPlatform.orderSync}</p></div> : "" }
            { inventorySync ? <div><p className="integrations-opt-grid__headers">Inventory Sync</p><p className="integrations-opt-grid__text">{props.data.contentfulPlatform.inventorySync}</p></div> : "" }
          </div>
          <div className="d-flex justify-content-around align-items-start mt-5">
            <Container style={{classes: "d-flex align-items-center justify-content-between" }}>
              <div className="integration-description">
                <p>
                  {props.data.contentfulPlatform.benefits ? <MDXProvider><MDXRenderer>{props.data.contentfulPlatform.benefits.childMdx.body}</MDXRenderer></MDXProvider> : props.data.contentfulPlatform.summary}
                </p>
                { task ? <a href={`https://support.jetti.io/jetti/en/vendor-onboarding/${props.data.contentfulPlatform.task.slug}`} target='_blank' className="btn-square btn-square__full mb-5">Setup guide</a> : "" }
                <Button style={{ href:`/partners/${props.data.contentfulPlatform.integrationCategory.slug}`, text: "Go back", shape: "btn-square btn-square__border"}}/>
              </div>
              <img src={img} className="illustration my-5 desktop-only" alt="integrtion-category-illustration"/>
            </Container>
          </div>
        </Container>
      </Layout>
     )
    }
  else {
    return (
      <Layout navbar="transparent">
          <Head title="Home" description="Dropshipping automation for Shopify, Magento and WooCommerce. Sync orders, inventory, shipping & payments." />
           
          <BackgroundShape style={{shape: "clip-fixed-small", color: "gradient", particles: true}}/>
            <Container style={{classes: "container position-relative" }}>
                <Header content={{title: props.data.contentfulPlatform.title, text: props.data.contentfulPlatform.summary }} style={{color: "text-center"}}/>
                <Button style={{ href:`https://onport.com/contacts/book-demo/`, text: "Get started", shape: "btn-contact btn-contact__reverse", active:""}}/>
            </Container>
            <Container style={{classes: "container text-center my-5" }}>
              <div className="feature-highlight__description mb-4 py-5 text-left row justify-content-center align-items-center">
                <div className="col-12 col-md-7">
                  <MDXProvider><MDXRenderer>{props.data.contentfulPlatform.benefits.childMdx.body}</MDXRenderer></MDXProvider>
                  { task ? <a href={`https://support.jetti.io/jetti/en/vendor-onboarding/${props.data.contentfulPlatform.task.slug}`} target='_blank' className="btn-square btn-square__full mt-3 d-inline-block">Setup guide</a> : "" }
                </div>
                <div className="col-12 col-md-5 d-flex align-items-center">
                  <img style={{height: "300px", width: "250px", zIndex: '1'}} src={require(`../assets/icons/platform/round/${props.data.contentfulPlatform.slug}.svg`)} alt=""/>
                  <img style={{height: "300px", width: "250px", marginLeft: '-10%'}} src={require(`../assets/icons/platform/round/onport.svg`)} alt=""/>
                </div>
              </div>
            </Container>
            <Container style={{classes: "container" }}>
                <div className="row w-75 mx-auto">
                {props.data.contentfulPlatform.icon.map((edge) => {
                    return(
                      <div className="landing-card col-12 col-md-3 my-5">
                        <div className="landing-card__icon-box">
                          <img src={`https://taact-brand-assets.netlify.app/assets/icons/jetti/${edge.icon}.svg`} className="landing-card__icon" alt="#" />
                        </div>
                        <p className="landing-card__title mt-0">{edge.title}</p>
                    </div>
                    )})
                  }
                </div>
              <SubHeader content={{title: "Feature Breakdown", text: ""}} style={{color: "text-center w-100"}}/>
              <p className="feature-highlight__description feature-breakdown d-flex justify-content-center blue">
                {props.data.contentfulPlatform.breakdown ? <MDXProvider><MDXRenderer>{props.data.contentfulPlatform.breakdown.childMdx.body}</MDXRenderer></MDXProvider> : props.data.contentfulPlatform.summary}
              </p>
              <div className="features-grid features-grid__center features-grid__4-col my-5 py-5">
                {props.data.allContentfulFeature.edges.slice(0,7).map((edge) => {
                    return(
                      <FeaturesCardMd content={{ slug: edge.node.slug, title: edge.node.title, icon: edge.node.icon}} style={{ color: ""}}/>
                    )})
                  }
                <FeaturesCardMd content={{ slug: "/", title: "Discover all the features", icon: "all"}} style={{ color: "feature-card-md__reverse"}}/>
              </div>
              <div className="mobile-only mx-auto">
                <FeaturesCardMd content={{ slug: "/", title: "Discover all the features", delay: secondDelay}} style={{ color: "feature-card-md__reverse"}}/>
              </div>
            </Container>
            <Container style={{classes: "container-fluid bg-blue py-5 mt-5"}}>
              <Container style={{classes: "container d-flex flex-column justify-content-around align-items-center py-5"}}>
                <SubHeader content={{title: props.data.contentfulPlatform.title, text: ""}} style={{color: "text-light text-center mx-auto my-0 py-0"}}/>
                <img style={{height: "200px", width: "200px"}} src={require(`../assets/icons/platform/round/${props.data.contentfulPlatform.slug}.svg`)} alt=""/>
                <p style={{fontSize: '1rem',fontWeight: "300", color: 'white', marginBottom: "3rem"}}>{props.data.contentfulPlatform.summary}</p>
                <a href="/contacts/book-demo" className="btn-home">Let's connect</a>
              </Container>
            </Container>
            <Footer content={{classes:"footer__alt"}}/>
            </Layout>
    )
  }
}

export default Partner
